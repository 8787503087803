import React from 'react';

function AboutMe(){
    return(
        <div className="About-Me">
            <p>
                &nbsp; &nbsp; Hello, My name is Ryan 
                and I'm a full 
                stack web developer. My primary skill set 
                is in C#, ASP .net backend 
                development, and MS SQL.  I also have experience in React.js 
                and I have no problem adapting to 
                any computer language.
            </p>
        </div>
    );
}

export default AboutMe;