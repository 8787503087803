import React from 'react';

import './PitchPerfect.css'

function PitchPerfect() {
    return (
        <div id="canvas-holder">
            <div id="pitch-perfect">
            </div>
        </div>
    );
}

export default PitchPerfect